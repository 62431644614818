import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { AgmCoreModule } from '@agm/core';
import {LayoutModule} from '@angular/cdk/layout';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    }),
    LayoutModule
  ],
  exports: [
    MDBBootstrapModulesPro,
    LayoutModule
  ]
})
export class MaterialModule { }
