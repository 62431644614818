import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading = false;


  constructor(private authService: AuthService,
              public modalRef: MDBModalRef,
              private toastrService: ToastService) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', {validators: [Validators.required, Validators.email]}),
      password: new FormControl('', {validators: [Validators.required]})
   });
  }

  onSubmit() {
    this.loginForm.disable();
    this.authService.login(this.loginForm.value).then(() => {
      this.modalRef.hide();
    }).catch((error) => {
      this.toastrService.error('Login in failed', {opacity: 1});
      this.modalRef.hide();
    });
      }
      onResetPassoerd() {
        this.authService.resetPassword(this.loginForm.value.email);
      }


}
