
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HammerModule } from '@angular/platform-browser';


import { MDBSpinningPreloader, ToastModule } from 'ng-uikit-pro-standard';
import { NavigationModule } from './navigation/navigation.module';
import { AppRoutingModule } from './app.routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { SubscribeModule } from './subscribe/subscribe.module';
import { PhotoGaleryComponent } from './photo-galery/photo-galery.component';
import { MaterialModule } from './material/material.module';
import { UploadPhotoComponent } from './upload-photo/upload-photo.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { LoginComponent } from './auth/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PhotoGaleryComponent,
    UploadPhotoComponent,
    ConfirmDeleteComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NavigationModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ToastModule.forRoot(),
    SubscribeModule,
    MaterialModule,
    ImageCropperModule,
    Ng2ImgMaxModule,
    ReactiveFormsModule,
    HammerModule

  ],
  entryComponents: [PhotoGaleryComponent, UploadPhotoComponent, ConfirmDeleteComponent, LoginComponent],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule {}
