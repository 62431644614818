import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { sideNavAnimation, sideNavContainerAnimation } from 'src/app/sidenav.animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [sideNavAnimation, sideNavContainerAnimation]
})
export class HeaderComponent implements OnInit, OnDestroy {

  isLogin = false;
  modalRef: MDBModalRef;
  pageSubscription: Subscription;
  constructor( private router: Router,
               private authService: AuthService,
               private modalService: MDBModalService) { }

  ngOnInit(): void {
  this.pageSubscription =  this.authService.userProvider.subscribe(user => {
      this.isLogin = !!user;
    });
  }

  login() {

    this.modalRef = this.modalService.show(LoginComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-scrollable modal-md modal-full-height',
      containerClass: '',
      animated: true,
    });

  }

  onControlPanel() {
    this.router.navigateByUrl('/control-panel');
  }

  logout() { this.authService.signOut(); }

  ngOnDestroy() {
    if (this.pageSubscription) {
      this.pageSubscription.unsubscribe();
    }
  }

}
