
<header>
<app-header></app-header>
</header>

<div class="row no-gutters">
  <div class="d-none d-md-block col-md-2 col-lg-1 mt-3">

    <div style="height: 90vh; margin-top: 50px;">
      <ul class="h-100 d-flex flex-column justify-content-between align-items-center px-2 px-md-5 pr-2 pr-md-5 z-depth-2 text-custom-size">
        <li class="d-flex flex-fill align-items-center" [routerLinkActive]="['active']">
          <a routerLink="/home" href="#" mdbWavesEffect class="text-center hover-text">
            <mdb-icon fas icon="home" [size]="iconSize" class="d-block "></mdb-icon>
            <span class="wow fadeIn text-custom-size" [ngClass]="{'text-black': !showText }">HOME</span>
          </a>
        </li>

        <li class="d-flex flex-fill align-items-center" [routerLinkActive]="['active']">
          <a routerLink="/entertainment" href="#" mdbWavesEffect class="text-center hover-text">
            <mdb-icon fas icon="microphone" [size]="iconSize" class="d-block"></mdb-icon>
            <span class="wow fadeIn text-custom-size" [ngClass]="{'text-black': !showText }">ENTERTAINMENT</span>
          </a>
        </li>

        <li class="d-flex flex-fill align-items-center" [routerLinkActive]="['active']">
          <a routerLink="/production" href="#" mdbWavesEffect class="text-center hover-text">
            <mdb-icon fas icon="film" [size]="iconSize" class="d-block"></mdb-icon>
            <span class="wow fadeIn text-custom-size" [ngClass]="{'text-black': !showText }">PRODUCTION</span>
          </a>
        </li>

        <li class="d-flex flex-fill align-items-center" [routerLinkActive]="['active']">
          <a routerLink="/highlight" href="#" mdbWavesEffect class="text-center hover-text">
            <mdb-icon fas icon="eye" [size]="iconSize" class="d-block"></mdb-icon>
            <span class="wow fadeIn text-custom-size" [ngClass]="{'text-black': !showText }">HIGHLIGHT</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-12 col-md-10 col-lg-11">

    <main [@fadeAnimation]="getRouterOutletState(o)">

      <router-outlet #o="outlet"  ></router-outlet>
    </main>

  </div>
</div>

