import {Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fadeAnimation } from './fade.animation';
import { ControlPanelService } from './services/control-panel.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnDestroy  {
  iconSize = '2x';
  showText: boolean;
  public getRouterOutletState(outlet) {

    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private ControlPanelServise: ControlPanelService) {

    this.router.events.subscribe(event => {

       if (event instanceof NavigationEnd) {
          setTimeout(() => {
            this.parseRoute(this.router.routerState.snapshot);
          }, 100);
       }
    });
}



parseRoute(node) {
    if (node.url) {
        const url = node.url;
        if (url === '/home') {
          this.showText = true;
        } else {
          this.showText = false;
        }
  }
}


ngOnDestroy() {
  this.ControlPanelServise.destroySubscriptio();
}


}
