import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastService, MDBModalRef } from 'ng-uikit-pro-standard';
import { AuthData } from '../auth/auth-data.model';



@Injectable({providedIn: 'root'})
export class AuthService {

userProvider = new BehaviorSubject<firebase.User>(null);
usersSubscription: Subscription;
modelRef: MDBModalRef;


constructor(private afAuth: AngularFireAuth,
            private toastrService: ToastService
            ) {
this.usersSubscription =  this.afAuth.authState.subscribe(user => {
    this.userProvider.next(user);
  });
}

login(authData: AuthData) {

  return this.afAuth.signInWithEmailAndPassword(authData.email, authData.password)
  .then(result => {}).catch(error => {
   this.showEror(error.message);
  });

  }



resetPassword(email: string) {
  return this.afAuth.sendPasswordResetEmail(email)
    .then(() => this.showinfo('Resting link has been sent to your eamil'))
    .catch(error => this.showEror(error.message));
}

signOut() {

  if (this.usersSubscription) {
    this.usersSubscription.unsubscribe();
  }
  this.afAuth.signOut().then(() => {
  }).catch(
    error => this.showEror(error.message)
  );
}

showEror(message: string) {
 const options = { enableHtml: true, positionClass: 'md-toast-top-right', toastClass: 'opacity-custom' };
 this.toastrService.error( message, 'Notification', options);
}

showinfo(message: string) {
  const options = { enableHtml: true, positionClass: 'md-toast-top-right', toastClass: 'opacity-custom' };
  this.toastrService.info( message, 'Notification', options);
 }

}
