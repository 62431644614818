<div class="modal-content">
  <div class="modal-header backgorund-1-1 white-text text-center py-4">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">Sign In</h4>
  </div>

  <div class="modal-body px-lg-5 pt-0">
    <form class="text-center customPrimaryColor" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="md-form">
        <input formControlName="email" type="email" id="materialLoginFormEmail" class="form-control" mdbInput />
        <label for="materialLoginFormEmail">E-mail</label>
      </div>

      <div class="md-form">
        <input formControlName="password" type="password" id="materialLoginFormPassword" class="form-control"
          mdbInput />
        <label for="materialLoginFormPassword">Password</label>
      </div>

      <div class="d-flex flex-column">
        <div class="p-2">
          <a class="customBlue" (click)="onResetPassoerd()">Forgot password?</a>
        </div>
      </div>
      <button [disabled]="loginForm.disabled" type="submit" mdbBtn color="danger" outline="true" rounded="true" block="true"
      class="my-4 waves-effect z-depth-0" mdbWavesEffect>
        Sign in
      </button>
      <!-- <mdb-spinner *ngIf="isLoading" spinnerType="big"></mdb-spinner> -->
    </form>
  </div>
</div>
