import { NgModule } from '@angular/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  {
   path: 'home',
   loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
   },
     {
       path: 'entertainment',
       loadChildren: () => import('./entertainments/entertainments.module').then(m => m.EntertainmentsModule)
       },
       {
         path: 'production',
         loadChildren: () => import('./production/production.module').then(m => m.ProductionModule)
         },
         {
         path: 'highlight',
         loadChildren: () => import('./ambiance/ambiance.module').then(m => m.AmbianceModule)
         },
         {
           path: 'contact-us',
           loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)
           },
           {
            path: 'control-panel',
            loadChildren: () => import('./control-panel/control-panel.module').then(m => m.ControlPanelModule)
            },
            {
              path: 'privacy',
              loadChildren: () => import('./privacy/privacy-routing.module').then(m => m.PrivacyRoutingModule)
              },
         { path: '**', component: NotFoundComponent},

];

@NgModule({
 imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled'})],
 exports: [RouterModule],
//   providers: [AuthGuard, AuthUserGuard]
})
export class AppRoutingModule { }
