
<mdb-navbar #navbar SideClass="navbar navbar-expand-md navbar-dark fixed-top customHeaderbackgorund pl-5 pr-0" [containerInside]="false">


  <mdb-navbar-brand>
    <a class="navbar-brand" href="#">
        <img src="../../../assets/IFrequencies_only.png" height="40" alt="">
    </a>
</mdb-navbar-brand>

  <links>


    <ul class="navbar-nav mr-auto font-weight-bold d-block d-md-none">

      <li [routerLinkActive]="['active']" class="nav-item">
        <a class="nav-link waves-light hover-text" [routerLink]="['/home']" mdbWavesEffect>
          <mdb-icon fas icon="home"></mdb-icon>HOME<span class="sr-only">(current)</span>
        </a>
      </li>
      <li [routerLinkActive]="['active']" class="nav-item">
        <a class="nav-link waves-light" [routerLink]="['/entertainment']"  mdbWavesEffect>
          <mdb-icon fas icon="microphone"></mdb-icon>ENTERTAINMENT</a>
      </li>
      <li [routerLinkActive]="['active']" class="nav-item">
        <a class="nav-link waves-light" [routerLink]="['/production']"  mdbWavesEffect>
          <mdb-icon fas icon="film"></mdb-icon>PRODUCTION</a>
      </li>
      <li [routerLinkActive]="['active']" class="nav-item">
        <a class="nav-link waves-light" [routerLink]="['/highlight']"  mdbWavesEffect>
          <mdb-icon fas icon="eye"></mdb-icon>HIGHLIGHT</a>
      </li>
      <li [routerLinkActive]="['active']" class="nav-item">
        <a class="nav-link waves-light" [routerLink]="['/contact-us']"  mdbWavesEffect>
          <mdb-icon fas icon="phone-alt"></mdb-icon>CONTACT US</a>
      </li>

    </ul>

    <div class="navbar-nav ml-auto nav-flex-icons">
      <div class="m-auto mr-md-0 icon-custom-size">
        <div  class="circle m-4 my-md-0 mx-md-1">
          <a href="https://www.facebook.com/Ifrequencies-102009834537484/" target="_blank" type="button" mdbBtn floating="true" color="fb" size="sm" class="waves-light m-0" mdbWavesEffect><mdb-icon class="rotate-animation" fab icon="facebook-f"></mdb-icon></a>
        </div>

        <div  class="circle m-4 my-md-0 mx-md-1">

          <a href="https://www.instagram.com/i.frequencies/" target="_blank" type="button" mdbBtn floating="true" size="sm" color="ins" class="waves-light m-0" mdbWavesEffect><mdb-icon class="rotate-animation" fab icon="instagram"></mdb-icon></a>
        </div>


          <div  class="circle m-4 my-md-0 mx-md-1">

            <a href="https://www.linkedin.com/in/i-frequencies-187538194" target="_blank" type="button" mdbBtn floating="true" size="sm" color="li" class=" waves-light m-0" mdbWavesEffect><mdb-icon class="rotate-animation" fab icon="linkedin-in"></mdb-icon></a>
          </div>


          <!-- <div  class="circle ">

            <a href="https://twitter.com/ifrequencies1/" target="_blank" type="button" mdbBtn floating="true" size="sm" color="tw" class="waves-light m-0" mdbWavesEffect><mdb-icon class="rotate-animation" fab icon="twitter"></mdb-icon></a>
          </div> -->



            <a *ngIf="!isLogin" (click)="login()" type="button" mdbBtn floating="true" size="sm" class="m-1 m-md-2 button-a-size waves-light d-none d-md-inline " mdbWavesEffect><mdb-icon class="rotate-animation hover-text" fas icon="lock"></mdb-icon></a>
            <a *ngIf="isLogin" (click)="logout()" type="button" mdbBtn floating="true" size="sm" class="m-1 m-md-2 button-a-size waves-light d-none d-md-inline" mdbWavesEffect><mdb-icon class="rotate-animation hover-text" fas icon="lock-open"></mdb-icon></a>
            <a *ngIf="isLogin" (click)="onControlPanel()" type="button" mdbBtn floating="true" size="sm" class="m-1 m-md-2 button-a-size waves-light d-none d-md-inline" mdbWavesEffect><mdb-icon class="rotate-animation hover-text" fas icon="plus-circle"></mdb-icon></a>

      </div>
    </div>

  </links>

</mdb-navbar>


