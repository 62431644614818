import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ControlPanelModel } from '../control-panel/control-panel.model';



@Injectable({
  providedIn: 'root'
})
export class ControlPanelService {

  public pagesDataSubject = new BehaviorSubject<ControlPanelModel[]>(null);
  fbSubscription: Subscription[] = [];
  pagesDataLoaded = false;

  constructor(private db: AngularFirestore, private fbs: AngularFireStorage) { }


setPageData(data: ControlPanelModel) {
  this.db.collection('pagesData').doc(data.pageName).set(data, {merge: true});
}



isPagesDataLoaded() {
  if (!this.pagesDataLoaded) {
    this.pagesDataLoaded = true;
    this.getData();
  }
}


getData() {
 this.fbSubscription.push(this.db.collection('pagesData')
     .snapshotChanges()
     .pipe(
         map(docData => {
           return docData.map(doc => {
                  return {
                 id: doc.payload.doc.id,
                 ...doc.payload.doc.data() as ControlPanelModel
                };
             });
         })
     )
     .subscribe((data: ControlPanelModel[]) => {
             this.pagesDataSubject.next([...data]);
         }));
}


destroySubscriptio() {
  this.fbSubscription.forEach(sub => {
    if (sub) {
      sub.unsubscribe();
    }
  });
}


}
