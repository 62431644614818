export const environment = {
  production: true,
  urlApi: 'https://ifrequencies-web.web.app/',
  firebase: {
    apiKey: 'AIzaSyAYFWzhHDC1b0r6NDocDT4DmgY5ygW1r4s',
    authDomain: 'ifrequencies-web.firebaseapp.com',
    databaseURL: 'https://ifrequencies-web.firebaseio.com',
    projectId: 'ifrequencies-web',
    storageBucket: 'ifrequencies-web.appspot.com',
    messagingSenderId: '99105894523',
    appId: '1:99105894523:web:ae4cdf87fa1768e159f2de',
    measurementId: 'G-F4V48RSMPX'
  }
};
