import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeComponent } from './subscribe.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { NgxCaptchaModule } from 'ngx-captcha';



@NgModule({
  declarations: [SubscribeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxCaptchaModule
  ],
  entryComponents: [SubscribeComponent]
})
export class SubscribeModule { }
